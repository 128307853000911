<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <cds-heading as="h3">
          Đăng nhập vào <span class="color-secondary">Trang quản trị</span>
        </cds-heading>
        <a-form-item name="username" label="Username">
          <a-input type="text" v-model:value="formState.username" />
        </a-form-item>
        <a-form-item name="password" label="Password">
          <a-input
              type="password"
              v-model:value="formState.password"
              placeholder="Password"
          />
        </a-form-item>
        <p style="color: red; text-align: center">{{ error }}</p>
        <div class="auth-form-action">
          <router-link class="forgot-pass-link" to="/auth/forgotPassword">
            Quên mật khẩu?
          </router-link>
        </div>
        <a-form-item>
          <cds-button
              class="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
          >
            {{ isLoading ? "Loading..." : "Đăng nhập" }}
          </cds-button>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive, ref, defineComponent } from "vue";
import { useStore } from "vuex";
import { AuthWrapper } from "./style";

const SignIn = defineComponent({
  name: "SignIn",
  components: { AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const error = computed(() => state.auth.error);
    const checked = ref(null);
    const handleSubmit = () => {
      dispatch("login", formState);
    };
    const formState = reactive({
      username: "admin",
      password: "123456",
    });
    return {
      isLoading,
      checked,
      handleSubmit,
      formState,
      error,
    };
  },
});

export default SignIn;
</script>
